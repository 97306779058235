import React, { useRef, useMemo, useState } from 'react';
import { StyleSheet, Text, View, Animated, Image, ScrollView, useWindowDimensions } from 'react-native';
import { useDynamicResponsiveValue } from 'quickly-react';
import { Page, Col, useRefState } from 'components';
import { COLOR } from 'const';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FirstPage from './FirstPage';
import HeroImageSlide from './HeroImageSlide';
import Tabbar from './Tabbar';
import Feature_1 from './Feature_1';
import Feature_2 from './Feature_2';
import Feature_3 from './Feature_3';
import CallToActionPage from './CallToActionPage';

export default function Home() {
  const [shouldTabBarSticky, getTabbarSticky, setTabBarSticky] = useRefState(false);

  const [shouldFeature1Sticky, getFeature1Sticker, setFeature1Sticky] = useRefState(false);
  const [shouldFeature2Sticky, getFeature2Sticker, setFeature2Sticky] = useRefState(false);
  const [shouldFeature3Sticky, getFeature3Sticker, setFeature3Sticky] = useRefState(false);

  const { width: innerWidth, height: innerHeight } = useWindowDimensions();
  const rV = useDynamicResponsiveValue(['xs', 'md']);
  const imageHeroPadding = rV({ xs: 20, md: 40 });

  const size = useMemo(() => {
    const heroContainerWidth = innerWidth * 0.8;
    const heroContainerHeight = heroContainerWidth * 1500 / 2880;
    const heroImageInnerContainerWidth = heroContainerWidth - imageHeroPadding * 2;
    const heroImageInnerContainerHeight = heroContainerHeight - imageHeroPadding * 2;
    const heroStartY = innerHeight - imageHeroPadding;
    const heroEndY = heroStartY + heroContainerHeight;
    const heroCenterY = heroStartY + heroContainerHeight / 2;
    const heroWhenCenterY = heroCenterY - innerHeight / 2;

    const tabBarWidth = 320;
    const tabBarHeight = 50;
    const tabBarStartY = heroEndY + 30;
    const tabBarEndY = tabBarStartY + tabBarHeight;
    const tabBarCenterY = tabBarStartY + tabBarHeight / 2;
    const tabBarWhenCenterY = tabBarCenterY - innerHeight / 2;
    const tabBarStartX = (innerWidth - tabBarWidth) / 2;

    const featureContainerWidth = heroContainerWidth;
    const featureContainerHeight = innerHeight - 120 - tabBarHeight * 2;
    const feature_1_StartY = tabBarStartY - 30;
    const feature_1_EndY = feature_1_StartY + innerHeight;
    const feature_2_StartY = feature_1_EndY;
    const feature_2_EndY = feature_1_EndY + innerHeight;
    const feature_3_StartY = feature_2_EndY;
    const feature_3_EndY = feature_3_StartY + innerHeight;

    return {
      hero: {
        width: heroContainerWidth,
        height: heroContainerHeight,
        innerWidth: heroImageInnerContainerWidth,
        innerHeight: heroImageInnerContainerHeight,
        startY: heroStartY,
        startX: innerWidth * 0.1,
        endY: heroEndY,
        whenCenterY: heroWhenCenterY,
      },
      tabbar: {
        width: tabBarWidth,
        height: tabBarHeight,
        startY: tabBarStartY,
        startX: tabBarStartX,
        endY: tabBarEndY,
        whenCenterY: tabBarWhenCenterY,
      },
      feature_1: {
        width: featureContainerWidth,
        height: featureContainerHeight,
        startY: feature_1_StartY,
        endY: feature_1_EndY,
      },
      feature_2: {
        width: featureContainerWidth,
        height: featureContainerHeight,
        startY: feature_2_StartY,
        endY: feature_2_EndY,
      },
      feature_3: {
        width: featureContainerWidth,
        height: featureContainerHeight,
        startY: feature_3_StartY,
        endY: feature_3_EndY,
      },
      callToAction: {
        width: innerWidth,
        height: innerHeight,
        startY: feature_3_EndY,
        endY: feature_3_EndY + innerHeight,
      }
    }
  }, [imageHeroPadding]);

  console.log('size', size);

  const scrollY = useRef(new Animated.Value(0)).current;
  scrollY.addListener(({ value }) => {

    const milestone = [
      'hero',
      'feature_1',
      'feature_2',
      'feature_3',
      'callToAction',
    ].reverse();

    for (let i = 0; i < milestone.length; i++) {
      if (value >= size[milestone[i]].startY) {
        console.log('Passed ' + milestone[i]);
        break;
      }
    }

    const handlecCondition = (shouldHandle, getter, setter) => {
      if (shouldHandle) {
        if (getter() !== true) setter(true);
      } else {
        if (getter() !== false) setter(false);
      }
    };

    [
      [value >= size.tabbar.startY - 30 && value <= size.feature_3.endY - 30, getTabbarSticky, setTabBarSticky],
      // [value >= size.feature_1.startY && value < size.feature_1.endY, getFeature1Sticker, setFeature1Sticky],
      // [value >= size.feature_2.startY && value < size.feature_2.endY, getFeature2Sticker, setFeature2Sticky],
      // [value >= size.feature_3.startY && value < size.feature_3.endY, getFeature3Sticker, setFeature3Sticky],
    ].forEach(v => {
      handlecCondition(v[0], v[1], v[2]);
    });

  })
  const heroImageTranslateX = scrollY.interpolate({
    inputRange: [0, size.hero.whenCenterY, size.hero.endY],
    outputRange: [0, 0, -innerWidth * 0.7],
    extrapolate: 'clamp',
  });
  const tabBarOpacity = scrollY.interpolate({
    inputRange: [0, size.tabbar.whenCenterY, (size.tabbar.whenCenterY + size.hero.endY) / 2, size.hero.endY],
    outputRange: [0, 0.35, 0.75, 1],
    extrapolate: 'clamp',
  });
  const feature_1_Opacity = scrollY.interpolate({
    inputRange: [0, size.feature_1.startY - 30, size.feature_1.startY, size.feature_1.endY - 30, size.feature_1.endY],
    outputRange: [0, 0, 1, 1, 0],
    extrapolate: 'clamp',
  });
  const feature_1_TranslateX = scrollY.interpolate({
    inputRange: [0, size.tabbar.startY - 60, size.tabbar.startY - 30],
    outputRange: [-size.feature_1.width - innerWidth * 0.1, -size.feature_1.width - innerWidth * 0.1, 0],
    extrapolate: 'clamp',
  });
  const feature_2_Opacity = scrollY.interpolate({
    inputRange: [0, size.feature_2.startY - 30, size.feature_2.startY, size.feature_2.endY - 30, size.feature_2.endY],
    outputRange: [0, 0, 1, 1, 0],
    extrapolate: 'clamp',
  });
  const feature_3_Opacity = scrollY.interpolate({
    inputRange: [0, size.feature_3.startY - 30, size.feature_3.startY, size.feature_3.endY - 30, size.feature_3.endY],
    outputRange: [0, 0, 1, 1, 0],
    extrapolate: 'clamp',
  });

  const tabBarCurrentIndexTranslateX = scrollY.interpolate({
    inputRange: [0, size.feature_1.startY, size.feature_2.startY, size.feature_3.startY],
    outputRange: [0, 0, size.tabbar.width / 3, size.tabbar.width * 2 / 3],
    extrapolate: 'clamp',
  });

  const callToActionOpacity = scrollY.interpolate({
    inputRange: [0, size.feature_3.endY-10, size.feature_3.endY],
    outputRange: [0, 0, 1],
    extrapolate: 'clamp',
  });

  const scrollRef = useRef<ScrollView>();
  const scrollToFeaturePageIndex = (i) => {
    const y = i === 0 ? size.feature_1.startY :
    i === 1 ? size.feature_2.startY :
    size.feature_3.startY;
    scrollRef.current?.scrollTo({ x: 0, y, animated: true });
  };

  return (
    <Page>
      <Animated.ScrollView
        ref={scrollRef}
        style={{
          flex: 1,
          height: '100%',
        }}
        // stickyHeaderIndices={
        //   shouldFeature1Sticky ? [2] :
        //     shouldFeature2Sticky ? [3] :
        //       shouldFeature3Sticky ? [4] :
        //         []
        // }
        stickyHeaderIndices={[2,3,4,5]}
        onScroll={Animated.event([
          {
            nativeEvent: {
              contentOffset: {
                y: scrollY
              }
            }
          }
        ], {
          useNativeDriver: true,
        })}
        scrollEventThrottle={15}
      >
        <FirstPage />
        <HeroImageSlide
          imageHeroPadding={imageHeroPadding}
          size={size.hero}
          heroImageTranslateX={heroImageTranslateX}
        />
        <Feature_1
          size={size.feature_1}
          opacity={feature_1_Opacity}
          translateX={feature_1_TranslateX}
          scrollY={scrollY}
        />
        <Feature_2
          size={size.feature_2}
          opacity={feature_2_Opacity}
          scrollY={scrollY}
        />
        <Feature_3
          size={size.feature_3}
          opacity={feature_3_Opacity}
          scrollY={scrollY}
        />
        <CallToActionPage
          opacity={callToActionOpacity}
        />
        <Tabbar
          size={size.tabbar}
          style={shouldTabBarSticky ? {
            display: 'none',
          } : {
            position: 'absolute',
            top: size.tabbar.startY,
            left: size.tabbar.startX,
            opacity: tabBarOpacity,
          }}
          indexTranslateX={tabBarCurrentIndexTranslateX}
          scrollToIndex={scrollToFeaturePageIndex}
        />
      </Animated.ScrollView>

      <Tabbar
        size={size.tabbar}
        style={shouldTabBarSticky ? {
          position: 'absolute',
          top: 30,
          left: size.tabbar.startX,
        } : {
          display: 'none'
        }}
        indexTranslateX={tabBarCurrentIndexTranslateX}
        scrollToIndex={scrollToFeaturePageIndex}
      />
    </Page>
  );
}
