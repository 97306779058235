import React, { useRef, useMemo, useState } from 'react';
import { StyleSheet, Text, View, Animated, Image, ScrollView, useWindowDimensions } from 'react-native';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useDynamicResponsiveValue } from 'quickly-react';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Page, RatioCol, RatioImage, Col, RatioCol2, LogoAnimation, useRefState } from 'components';
import { DEVICE_INFO, COLOR } from 'const';

export default function HeroImageSlide({
  size,
  imageHeroPadding,
  heroImageTranslateX,
}) {
  const listHeroImages = [
    {
      img: require('../../../material/intro-image-2.jpg'),
      ratio: 2398 / 1348
    },
    {
      img: require('../../../material/intro-image-1.jpg'),
      ratio: 2027 / 1347
    },
  ];

  return (
    <Animated.View
      style={{
        alignSelf: 'center',
        marginTop: -imageHeroPadding,
        width: size.width,
        height: size.height,
        borderRadius: 20,
        backgroundColor: 'rgba(0,0,0,0.1)',
        overflow: 'hidden',
      }}
    >
      <Animated.View
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          flexDirection: 'row',
          transform: [
            { translateX: heroImageTranslateX },
          ],
          padding: imageHeroPadding,
        }}
      >
        {listHeroImages.map((val, i) => (
          <Col
            height='100%'
            aspectRatio={val.ratio}
            marginRight={imageHeroPadding}
            key={'hero-' + i}
          >
            <Image
              source={val.img}
              style={{
                width: '100%',
                height: '100%',
                borderRadius: 20,
              }}
            />
          </Col>
        ))}
      </Animated.View>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoRow: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  logoRowText: {
    flex: 1,
  },
  introContent: {
    marginTop: 40,
    paddingHorizontal: '10%',
  },
  buttonRow: {
    marginTop: 40,
    flexDirection: 'row',
  },
  page2Container: {
    flex: 1,
    alignItems: 'center',
  }
});
