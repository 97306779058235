export const COLOR = {
  MAIN: '#28A7E7',
  WHITE: 'white',
}

export const FONT = {
  defaultFont: 'Roboto',
  bold: 'Roboto',
  extraBold: 'Roboto',
  semiBold: 'Roboto',
  light: 'Roboto',
  medium: 'Roboto',
  thin: 'Roboto',
  black: 'Roboto',
};

export { DEVICE_INFO } from './deviceInfo';