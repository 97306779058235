import React, { useRef, useMemo, useState } from 'react';
import { StyleSheet, Text, View, Animated, Image, ScrollView, useWindowDimensions } from 'react-native';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useDynamicResponsiveValue } from 'quickly-react';
import { Page, RatioCol, RatioImage, Col, RatioCol2, LogoAnimation, useRefState, Grid } from 'components';
import { DEVICE_INFO, COLOR } from 'const';

export default function Feature_3({
  size,
  opacity,
  scrollY,
}) {
  const rV = useDynamicResponsiveValue();
  const slideUp = scrollY.interpolate({
    inputRange: [size.startY - 30, size.startY, size.endY - 30, size.endY],
    outputRange: [size.height, 0, 0, -size.height],
    extrapolate: 'clamp',
  });
  const slideRightIn = opacity.interpolate({
    inputRange: [0, 1],
    outputRange: [size.width, 0],
    extrapolate: 'clamp',
  });
  return (
    <Page>
      <Col flex1 middle>
        <Animated.View
          style={{
            width: size.width,
            height: size.height,
            borderRadius: 20,
            backgroundColor: 'rgba(0,0,0,0.1)',
            opacity,
            overflow: 'hidden',
          }}
        >
          <Grid flex1 stretch xs='100%' md='1:1'>
            <Animated.View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                padding: '5%',
                transform: [
                  { translateY: slideUp }
                ]
              }}
            >
              <Typography variant={'h4'}>
                Searching and showing <span style={{ color: COLOR.MAIN }}>subtitles</span> for your movies and shows
              </Typography>
              <Typography variant={'body1'} mt={2}>
                Automatically searching subtitles from video name and caption. Support multiple languages and subtitles resync tool.
              </Typography>
            </Animated.View>
            <Animated.View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Animated.View
                style={{
                  width: rV({ xs: '80%', md: '70%' }),
                  transform: [
                    { translateX: slideRightIn }
                  ],
                  borderRadius: 20,
                  overflow: 'hidden',
                }}
              >
                <RatioImage
                  source={require('../../../material/feature-3.jpg')}
                  width='100%'
                  ratio={1451 / 946}
                />
              </Animated.View>
            </Animated.View>
          </Grid>
        </Animated.View>
      </Col>
    </Page>
  );
}