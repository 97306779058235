import React, { useEffect } from 'react';
import { View } from 'react-native';
import { Col } from '../base';
import './LogoAnimation.css';

const LogoAnimation = (p) => {

  const width = p.width || 300;
  const height = p.width || 300;

  const animateLogo = () => {
    var animEl = document.querySelectorAll('.anim-pyraplayer-logo')[0];
    // animEl.classList.remove('animate');
    // animEl.classList.remove('animate2');

    animEl.classList.add('animate');
    setTimeout(function () {
      animEl.classList.add('animate2');
    }, 700);
  };
  
  useEffect(() => {
    animateLogo();
  }, []);

  return (
    <Col {...p} width={width} height={height}>
      <div
        className="wrapper"
        style={{
          transform: `scale(${width/1024})`,
          transformOrigin: 'top left',
        }}
      >
        <img className="anim-pyraplayer-logo" src=""/>
      </div>
    </Col>
  );
}

export default LogoAnimation;