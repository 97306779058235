import React from 'react';
import { Animated, Text } from 'react-native';
import { COLOR } from 'const';
import { Col } from 'components';

export default function Tabbar({ style, size, indexTranslateX, scrollToIndex }) {
  return (
    <Animated.View
      style={{
        width: size.width,
        height: size.height,
        borderRadius: size.height / 2,
        borderWidth: 2,
        borderColor: COLOR.MAIN,
        flexDirection: 'row',
        backgroundColor: 'white',
        overflow: 'hidden',
        ...style,
      }}
    >
      <Col flex1 middle onPress={() => scrollToIndex(0)}>
        <Text>Folder</Text>
      </Col>
      <Col flex1 middle onPress={() => scrollToIndex(1)}>
        <Text>Organize</Text>
      </Col>
      <Col flex1 middle onPress={() => scrollToIndex(2)}>
        <Text>Subtitle</Text>
      </Col>
      <Animated.View
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: size.width / 3,
          height: 3,
          backgroundColor: COLOR.MAIN,
          transform: [
            { translateX: indexTranslateX },
          ]
        }}
      />
    </Animated.View>
  );
}