import React from 'react';
import { View } from 'react-native';
import Svg, {
  Defs,
  ClipPath,
  Path,
  Rect,
  G,
  Polygon,
  Use,
} from 'react-native-svg';

const SvgComponent = props => (
  <Svg width={300} height={300} viewBox="0 0 110 110" {...props}>
    <Defs>
      <Path
        id="prefix__a"
        d="M25 10a20 20 0 1 0 0 40 20 20 1 1 0 0-40m20 0a20 20 0 1 0 0 40 20 20 1 1 0 0-40"
      />
      {/* <ClipPath id="prefix__b" clipRule="nonzero">
        <Use xlinkHref="#prefix__a" clipRule="nonzero" />
      </ClipPath>
      <ClipPath id="prefix__c" clipRule="evenodd">
        <Path
          id="prefix__a"
          y={50}
          d="M25 10a20 20 0 1 0 0 40 20 20 1 1 0 0-40m20 0a20 20 0 1 0 0 40 20 20 1 1 0 0-40"
        />
      </ClipPath>
      <ClipPath id="prefix__d" clipRule="evenodd">
        <Use xlinkHref="#prefix__a" clipRule="evenodd" y={50} />
      </ClipPath> */}
    </Defs>
    <G>
      <Use
        fillRule="evenodd"
        xlinkHref="#prefix__a"
        x={0}
        y={0}
        stroke="#daa520"
      />
    </G>
  </Svg>
);

export default class App extends React.Component {
  render() {
    return (
      <View
        style={{
          flex: 1,
          alignContent: 'center',
          justifyContent: 'center',
          backgroundColor: 'pink',
        }}
      >
        <SvgComponent />
      </View>
    );
  }
}