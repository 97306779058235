import React, { useRef, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useDynamicResponsiveValue } from 'quickly-react';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Page, LogoAnimation } from 'components';
import Logo from '../../../material/Logo';
import { DEVICE_INFO, COLOR } from 'const';

export default function HomeFirstPage() {
  const rV = useDynamicResponsiveValue(['xs', 'md']);
  const logoSize = rV({ xs: 100, md: 150 });
  const headingProps = rV({
    xs: {
      style: { textAlign: 'center' },
      mb: 1,
      mt: 1,
      variant: 'h4',
    },
    md: {
      ml: 3,
      mb: 0,
      variant: 'h1',
    }
  });
  const subHeadingProps = rV({
    xs: {
      style: { color: '#8c8c8c', textAlign: 'center' }
    },
    md: {
      style: { color: '#8c8c8c' },
      ml: 4,
      mb: 0,
    },
  });
  const rowToColResponsive = { flexDirection: rV({ xs: 'column', md: 'row' }) };


  return (
    <Page>
      <View style={styles.container}>
        <View style={[styles.logoRow, rowToColResponsive]}>
          {DEVICE_INFO.IS_FIREFOX ? (
            <Logo width={logoSize} height={logoSize} />
          ) : (
            <LogoAnimation width={logoSize} height={logoSize} />
          )}
          <View style={styles.logoRowText}>
            <Typography component="div" gutterBottom {...headingProps}>
              Pyra Player
            </Typography>
            <Typography {...subHeadingProps} variant={'subtitle1'} gutterBottom>
              Your own media center on the internet
            </Typography>
          </View>
        </View>
        <View style={styles.introContent}>
          <Typography style={{ textAlign: 'center' }} variant={rV({ md: 'h5', xs: 'body1' })}>
            Pyra Player is another Telegram client, focusing on video content. <br />You have new a way to enjoy your videos anywhere, across multiple platforms.
          </Typography>
        </View>
        <View style={[styles.buttonRow, rowToColResponsive]}>
          <a href="https://web.pyraplayer.com" style={{ textDecorationLine: 'none' }}>
            <Button variant="contained" style={{ margin: 10, backgroundColor: COLOR.MAIN, height: 50, paddingLeft: 20, paddingRight: 20 }}>
              Try the web app
            </Button>
          </a>
          <Button
            onClick={() => { alert('Demo video coming soon') }}
            variant="outlined" style={{ margin: 10, borderColor: COLOR.MAIN, color: COLOR.MAIN, height: 50, paddingLeft: 20, paddingRight: 20 }}
          >
            {/* @ts-ignore */}
            <PlayCircleIcon color="#28A7E7" style={{ marginRight: 10 }} />
            Watch demo
          </Button>
        </View>
      </View>
    </Page>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoRow: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  logoRowText: {
    flex: 1,
  },
  introContent: {
    marginTop: 40,
    paddingHorizontal: '10%',
  },
  buttonRow: {
    marginTop: 40,
    flexDirection: 'row',
  },
});
