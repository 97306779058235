import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width={1024} height={1024} {...props}>
    <defs>
      <clipPath id="clip-Artboard_1">
        <path d="M0 0h1024v1024H0z" />
      </clipPath>
      <style>{".cls-3{fill:#fff}.cls-4{fill:none}"}</style>
    </defs>
    <g
      id="Artboard_1"
      data-name="Artboard \u2013 1"
      style={{
        clipPath: "url(#clip-Artboard_1)",
      }}
    >
      <g
        id="Group_17"
        data-name="Group 17"
        transform="translate(-170.686 -170.686)"
      >
        <g
          id="Ellipse_1"
          data-name="Ellipse 1"
          transform="translate(170.686 170.686)"
          style={{
            fill: "#28a7e7",
            stroke: "#707070",
          }}
        >
          <circle
            cx={512}
            cy={512}
            r={512}
            style={{
              stroke: "none",
            }}
          />
          <circle className="cls-4" cx={512} cy={512} r={511.5} />
        </g>
        <g
          id="Group_14"
          data-name="Group 14"
          transform="translate(433.518 354.115)"
        >
          <g id="Group_16" data-name="Group 16">
            <g id="Group_15" data-name="Group 15">
              <path
                id="Path_7"
                data-name="Path 7"
                className="cls-3"
                d="m1138.959 1041.083-294.275 267.606a76.359 76.359 0 0 1-7.853 6.246l39.126-373.643c2.969-28.338-33.926-41.816-49.944-18.254l-81.954 120.59 21.464-204.984c5.479-52.217 62.618-81.85 108.444-56.2l251.484 140.767c43.594 24.362 50.435 84.295 13.507 117.871"
                transform="translate(-610.573 -773.061)"
              />
              <path
                id="Path_8"
                data-name="Path 8"
                className="cls-4"
                d="m892.466 836.516-39.122 373.644c-49.74 34.729-120.7-4.572-114.069-67.956l21.293-203.351 81.954-120.59c16.022-23.563 52.912-10.089 49.944 18.254"
                transform="translate(-627.082 -668.286)"
              />
              <path
                id="Path_9"
                data-name="Path 9"
                className="cls-3"
                d="m938.263 1109.35-5.2 49.665c-6.845 65.1-89.145 89.146-129.912 37.94l-75.217-94.45a73.047 73.047 0 0 1-3.281-86.626l120.839-177.836-21.3 203.351c-6.629 63.38 64.333 102.686 114.069 67.956"
                transform="translate(-712 -567.477)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent
