import React, { useRef, useMemo, useState } from 'react';
import { StyleSheet, Text, View, Animated, Image, ScrollView } from 'react-native';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useDynamicResponsiveValue } from 'quickly-react';
import { Page, RatioCol, RatioImage, Col, RatioCol2, LogoAnimation, useRefState, Grid } from 'components';
import { DEVICE_INFO, COLOR } from 'const';

export default function Feature_1({
  size,
  opacity,
  translateX,
  scrollY,
}) {
  const rV = useDynamicResponsiveValue();
  const slideUp = scrollY.interpolate({
    inputRange: [size.startY - 30, size.startY, size.endY - 30, size.endY],
    outputRange: [size.height, 0, 0, -size.height],
    extrapolate: 'clamp',
  });
  const slideRightIn = opacity.interpolate({
    inputRange: [0, 1],
    outputRange: [size.width, 0],
    extrapolate: 'clamp',
  });
  return (
    <Page>
      <Col flex1 middle>
        <Animated.View
          style={{
            width: size.width,
            height: size.height,
            opacity,
            transform: [
              { translateX },
            ],
            borderRadius: 20,
            backgroundColor: 'rgba(0,0,0,0.1)',
            overflow: 'hidden',
          }}
        >
          
          <Grid flex1 stretch xs='100%' md='1:1'>
            <Animated.View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                padding: '5%',
                transform: [
                  { translateY: slideUp }
                ]
              }}
            >
              <Typography variant={'h4'}>
                Show all your Telegram content in folder <span style={{ color: COLOR.MAIN }}>Pyraplayer</span>
              </Typography>
              <Typography variant={'body1'} mt={2}>
                Once you're logged-in using Telegram account, it will create a new folder "Pyraplayer" and only display video content from that folder.
              </Typography>
            </Animated.View>
            <Animated.View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Animated.View
                style={{
                  width: rV({ xs: '80%', md: '50%' }),
                  transform: [
                    { translateX: slideRightIn }
                  ],
                  borderRadius: 20,
                  overflow: 'hidden',
                }}
              >
                <RatioImage
                  source={require('../../../material/feature-1.jpg')}
                  width='100%'
                  ratio={720/678}
                />
              </Animated.View>
              {/* <Animated.Image
                source={require('../../../material/feature-1.jpg')}
                style={{
                  width: 720/2.2,
                  height: 678/2.2,
                  borderRadius: 20,
                  transform: [
                    { translateX: slideRightIn }
                  ]
                }}
              /> */}
            </Animated.View>
          </Grid>
        </Animated.View>
      </Col>
    </Page>
  );
}