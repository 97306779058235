import React from 'react';
import Div100vh from 'react-div-100vh';
import {
  View
} from 'react-native';

const Page = ({ children }: any) => {
  return (
    <Div100vh>
      <View style={{ height: '100%', width: '100%' }}>
        {children}
      </View>
    </Div100vh>
  );
};

export default Page;