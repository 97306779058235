import React, { memo } from 'react';
import { Image, ImageProps } from 'react-native';
import RatioCol from './RatioCol';

interface IRatioImage {
  width: number | string;
  ratio: number; // width / height,
  source: ImageProps['source'];
  [otherProps: string]: any;
}

const RatioImage = ({ width, ratio, source, ...otherProps }: IRatioImage) => {
  return (
    <RatioCol width={width} ratio={ratio} {...otherProps}>
      <Image source={source} style={{ width: '100%', height: '100%' }} resizeMode="cover" />
    </RatioCol>
  );
};

export default RatioImage;
